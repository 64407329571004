import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Spacings from "@finanzchef24gmbh/design-system/src/Spacings";
import Text from "@finanzchef24gmbh/design-system/src/Text";

const Wrapper = styled.ul``;

const NavLink = styled.div`
  text-decoration: none;
  color: ${(props) => props.theme.palette.brand.dark};
  .active > & {
    background-color: ${(props) => props.theme.palette.brand[200]};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.brand[100]};
  }
`;

const sortNodesByName = ({ node: firstNode }, { node: secondNode }) => {
  if (firstNode.frontmatter.name < secondNode.frontmatter.name) {
    return -1;
  }
  if (firstNode.frontmatter.name > secondNode.frontmatter.name) {
    return 1;
  }
  return 0;
};

const NavSection = ({ title, nodes }) => (
  <Wrapper>
    {title && (
      <Spacings.InsetSquish scale="big">
        <Text textStyle="subheadline">{title}</Text>
      </Spacings.InsetSquish>
    )}
    {nodes.sort(sortNodesByName).map(({ node }) => (
      <li key={node.id}>
        <Link
          to={node.frontmatter.route}
          getProps={({ isCurrent }) => ({
            className: isCurrent ? "active" : null,
            style: { textDecoration: "none" },
          })}
        >
          <NavLink>
            <Spacings.InsetSquish scale="big">
              {node.frontmatter.name}
            </Spacings.InsetSquish>
          </NavLink>
        </Link>
      </li>
    ))}
  </Wrapper>
);

NavSection.propTypes = {
  title: PropTypes.string,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        route: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
};

NavSection.defaultProps = {
  nodes: [],
};

export default NavSection;
