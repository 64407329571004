import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import styled, {
  createGlobalStyle,
  StyleSheetManager,
  ThemeProvider,
} from "styled-components";

import designSystemTheme from "@finanzchef24gmbh/design-system/src/designTokens";
import getGlobalDesignSystemStyles from "@finanzchef24gmbh/design-system/src/globalStyles";
import InterUiRegularWoff from "../fonts/Inter-Regular.woff";
import InterUiRegularWoff2 from "../fonts/Inter-Regular.woff2";
import InterUiSemiBoldWoff from "../fonts/Inter-SemiBold.woff";
import InterUiSemiBoldWoff2 from "../fonts/Inter-SemiBold.woff2";
import shouldForwardProp from "../utils/shouldForwardProp";
import Header from "./header";
import Nav from "./nav";
import globalGatsbyStyles from "./globalStyles";

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: 100vh;
`;

const GlobalStyles = createGlobalStyle`
  ${getGlobalDesignSystemStyles()}

  @font-face {
    font-family: 'Inter UI';
    src:  url('${InterUiSemiBoldWoff2}') format('woff2'),
          url('${InterUiSemiBoldWoff}') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter UI';
    src:  url('${InterUiRegularWoff2}') format('woff2'),
          url('${InterUiRegularWoff}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fonts-loaded {
    font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-feature-settings: "tnum";

    /*
    Overwrite the browsers defaults for these elements as some browsers e.g.
    Chrome set these elements to be system-ui which is more specific than the
    bodys font family.
    Also a CSS reset is not enough here, as it only sets the font to inherit
    which causes those elements to inherit the default browser styles again.
    */
    input, textarea, select, button {
      font-family: 'Inter UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }

  ${globalGatsbyStyles}
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query TitleAndMenu {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme={designSystemTheme}>
          <StyledLayout>
            <GlobalStyles />
            <Sidebar>
              <Header siteTitle={data.site.siteMetadata.title} />
              <Nav />
            </Sidebar>
            <div
              style={{
                margin: "0 auto",
                maxWidth: "70ch",
                padding: "3rem 1.0875rem 1.45rem",
              }}
            >
              {children}
            </div>
          </StyledLayout>
        </ThemeProvider>
      </StyleSheetManager>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
